








































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import ClaimerDetailUserViewModel from '../viewmodels/claimer-detail-user-viewmdodel'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

@Observer
@Component({
  components: {
    ConnectMetamask: () => import('../../../components/ConnectMetamask.vue')
  }
})
export default class Claimer extends Vue {
  @Provide() vm = new ClaimerDetailUserViewModel()
  wallet = walletStore
  winnerListHeaders = [
    {
      text: '-',
      align: 'left',
      sortable: false,
      value: 'stt'
    },
    {
      text: 'Wallet address',
      align: 'left',
      sortable: false,
      value: 'address'
    },
    {
      text: 'amount',
      align: 'right',
      sortable: false,
      value: 'amount'
    },
    {
      text: '-',
      align: 'right',
      sortable: false,
      value: 'state'
    }
  ]

  vestingSchedulerHeaders = [
    {
      text: '#',
      align: 'left',
      sortable: false,
      value: 'stt'
    },
    {
      text: 'Date',
      align: 'left',
      sortable: false,
      value: 'date'
    },
    {
      text: 'Claim Amount (%)',
      align: 'center',
      sortable: false,
      value: 'percentage'
    },
    {
      text: 'Total token Allocation',
      align: 'center',
      sortable: false,
      value: 'allocationAmount'
    },
    { text: '-', align: 'center', sortable: false, value: 'status' }
  ]

  destroyed() {
    this.vm.destroy()
  }

  @Watch('$route.query', { immediate: true }) async changePool(params) {
    if (params && params.chainId && params.pool) {
      await this.vm.loadPool({ chainId: params.chainId, poolId: params.pool })
    } else {
      snackController.error('Pool not found, please check again')
    }
  }
}
