import { blockchainHandler } from '@/blockchainHandlers'
import { SolidityMultiClaimContract } from '@/blockchainHandlers/multiclaim-contract-solidity'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { FULL_100, Zero } from '@/constants'
import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
// import { Wallet } from '@project-serum/anchor'
import { cloneDeep, isNumber } from 'lodash'
import { action, autorun, computed, observable, runInAction, when } from 'mobx'
import { reaction, IReactionDisposer } from 'mobx'
import { asyncAction } from 'mobx-utils'

export default class ClaimerDetailUserViewModel {
  _disposers: IReactionDisposer[] = []
  @observable multiclaimHandler?: SolidityMultiClaimContract
  @observable pool?: any
  @observable poolId: any
  @observable chainId: any
  @observable pageLoading = false
  @observable searchWalletText = ''
  @observable accountInfo: any
  @observable vestingScheduler: any = []
  @observable loadedFirst = false
  @observable loadedSecond = false
  constructor() {
    this._disposers = [
      autorun(() => {
        if (walletStore.connected && this.multiclaimHandler && this.poolId) {
          this.fetchAccountInfo()
        }
      }),
      autorun(() => {
        if (this.pool && !this.accountInfo && !this.loadedFirst) {
          this.changeVestingScheduler(this.pool.vestingScheduler)
        } else if (this.accountInfo && !this.loadedSecond) {
          this.changeVestingScheduler(this.accountInfo.vestingScheduler)
        }
      })
    ]
  }

  @action changeVestingScheduler(vestingScheduler) {
    if (!this.accountInfo) {
      this.loadedFirst = true
    } else {
      this.loadedSecond = true
    }
    this.vestingScheduler = cloneDeep(vestingScheduler).map(item => ({ ...item, loading: false }))
  }

  @asyncAction *fetchAccountInfo() {
    this.multiclaimHandler?.injectProvider()
    this.accountInfo = yield this.multiclaimHandler?.fetchAccountInfo(this.poolId, walletStore.account)
  }

  destroy() {
    this._disposers.forEach(d => d())
  }

  @asyncAction *loadPool(params) {
    try {
      const { chainId, poolId } = params
      this.pageLoading = true
      this.chainId = chainId
      this.multiclaimHandler = blockchainHandler.multiclaimContractFactory({
        chainId
      }) as SolidityMultiClaimContract
      this.pool = yield this.multiclaimHandler.fetchClaimPoolDetail(poolId)
      this.poolId = poolId
      this.pageLoading = false
    } catch (e) {
      // snackController.error(e.message || e.msg)
      snackController.error('Pool not found, please check again')
    } finally {
      this.pageLoading = false
    }
  }

  @action searchInputChange(value) {
    this.searchWalletText = value.trim().toLowerCase()
  }

  @computed get validWinnerInfos() {
    return this.winnerInfos.filter(item => item.address.toLowerCase().includes(this.searchWalletText))
  }

  @computed get validVestingScheduler() {
    let totalVestingAmount
    if (this.accountInfo?.isWinner) {
      totalVestingAmount = this.winnerInfos?.find(item => item.address === walletStore.account)?.amount || Zero
    } else {
      totalVestingAmount = this.pool?.amount || Zero
    }
    return this.vestingScheduler.map(item => ({
      ...item,
      allocation: item.percentage.mulUnsafe(totalVestingAmount).divUnsafe(FULL_100)
    }))
  }
  @asyncAction *claimToken(vestingId) {
    try {
      this.vestingScheduler[vestingId].loading = true
      const amount = this.validVestingScheduler[vestingId].allocation
      yield this.multiclaimHandler?.claimTokens(walletStore.account, this.poolId, vestingId)
      this.changePool(amount)
      this.changeAccountInfo(vestingId, amount)
      this.updateVestingSchedule(vestingId)
      snackController.success('Token claimed successfully')
    } catch (e) {
      snackController.error(e.message || e.msg)
      console.error(e)
    } finally {
      this.vestingScheduler[vestingId].loading = false
    }
  }

  @action changePool(amount) {
    const poolClone = cloneDeep(this.pool)
    poolClone.winnerInfos.map(item => {
      if (item.address === walletStore.account) {
        item.totalClaimed = item.totalClaimed.addUnsafe(amount)
        item.isClaimed = item.totalClaimed.toString() === item.amount.toString()
      }
      return item
    })
    this.pool = poolClone
  }

  @action changeAccountInfo(vestingId, amount) {
    const accountInfo = cloneDeep(this.accountInfo)
    accountInfo.vestingScheduler[vestingId].isClaimed = true
    accountInfo.claimedAmount = accountInfo.claimedAmount.addUnsafe(amount)
    this.accountInfo = accountInfo
  }

  @action updateVestingSchedule(index) {
    this.vestingScheduler[index].isClaimed = true
  }

  @computed get isWinner() {
    return this.accountInfo?.isWinner || false
  }
  @computed get winnerInfos() {
    return this.pool?.winnerInfos || []
  }
  @computed get accountClaimedAmount() {
    return this.accountInfo?.claimedAmount || 0
  }

  @computed get poolName() {
    return this.pool?.name
  }
  @computed get poolAmount() {
    return this.pool?.amount
  }
  @computed get description() {
    return this.pool?.description
  }
  @computed get tokenName() {
    return this.pool?.tokenInfo?.symbol
  }
  @computed get coverUrl() {
    return this.pool?.coverUrl
  }
  @computed get avatarUrl() {
    return this.pool?.avatarUrl
  }
  @computed get isValidChain() {
    const chainId = isNumber(+this.chainId) ? +this.chainId : this.chainId
    return chainId && this.pool && (chainId === 97 || chainId === 56)
  }

  @computed get isCorrectChain() {
    return +walletStore.chainId && +walletStore.chainId === this.chainId
  }
}
